@font-face {
    font-family: Chalk;
    src: url('/assets/medad_sis_ui/fonts/KGTenThousandReasons.ttf');
}

.chalk-font {
    font-family: Chalk, Arial, sans-serif!important;
}

.custom-alert {
    padding: 15px;
    font-weight: 500;
    background:#fff;
    border-left: #090909 3px solid;
}
.custom-alert.warning {
    background: #fffbee;
    border-left-color: #ffa200;
}
[dir=rtl] .custom-alert {
    padding: 15px;
    font-weight: 500;
    background:#fff;
    border-left: none;
    border-right: #090909 3px solid;
}
[dir=rtl] .custom-alert.warning {
    background: #fffbee;
    border-left-color: transparent;
    border-right-color: #ffa200;
}

a.nav-link {
    position: relative
}
a.active.nav-link {
    color: var(--primary)!important;
}
a.active.nav-link:after {
    content: ' ';
    background-color: var(--primary);
    height: 4px;
    display: block;
    top: 100%;
    position: absolute;
    margin-top: 15px;
    left: 0;
    width: calc(100% - 20px);
    margin-left: calc(50% - calc(100% - 20px) / 2);
}

.wrap-collapsible {
    margin-bottom: 1.2rem 0;
  }
  
  .wrap-collapsible input[type='checkbox'] {
    display: none;
  }
  
  .wrap-collapsible .lbl-toggle {
    display: block;      
    padding: 1rem 2rem;  
    color: #192734;
    background: #F5F5F5;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 5px
  }
  
  .wrap-collapsible .lbl-toggle:hover {
    color: #000;
  }
  
  .wrap-collapsible .lbl-toggle::before {
    content: ' ';
    display: inline-block;
  
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: rotate(90deg);
  
    transition: transform .2s ease-out;
  }

  .wrap-collapsible .lbl-toggle span {
      margin: 0 7px;
  }
  
  .wrap-collapsible .toggle:checked + .lbl-toggle::before {
    transform: rotate(-90deg) translateX(2px);
  }
  
  .wrap-collapsible .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    margin-top: 0;
  }
  
  .wrap-collapsible .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 200vh;
    padding-top: 2px;
  }
  
  .wrap-collapsible .collapsible-content .content-inner {
    padding: .5rem 1rem;
  }

.container-wide {
    width: 100%;
    max-width: 1470px;
    margin: 0 auto;
    position: relative;
    padding: 0 20px;
}

.light-text {
    color: #686868;
}
.lighter-text {
    color: #7c7c7c;
}
.text-smaller {
    font-size: 90%;
}

.web-footer {
    padding: 5rem 0 0;
    min-height: 140px;
}

.footer-info {
    color: #fff;
    background-color: var(--primary);
}
.footer-info a {
    color: #fff!important;
}

.page-panel {
    padding: 25px 30px;
    background: #fff;
    border: 2px solid #DDDDDD;
}
.section-title {
    margin-bottom: 10px
}

.students-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 15px 0 25px
}

table thead th {
    background-color: var(--secondary);
    color: #fff;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F8F8F8;
}
.table th, .table td {
    border-top: 1px solid #f3f3f3;
}

.panel-filter-wrapper {
    margin-bottom: 20px;
    /* padding: 0 15px; */
    display: flex;
    justify-content: flex-end;
}
.panel-filter-wrapper > div {
    display: flex;
    align-items: flex-end;
    padding-left: 0;
}
[dir=rtl] .panel-filter-wrapper > div {
    padding-left: 15px;
    padding-right: 0;
}
.panel-filter-wrapper .labelled-dropdown {
    width: 100%;
}

.datagrid-table .formio-component-date {
    min-width: 145px;
}